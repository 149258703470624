import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import IMG01 from "client/assets/images/icons/male.png";

const MenuLoginSignup = ({ isLoggedIn, currentUser, logout }) => {
  const [t] = useTranslation();

  return (
    <ul className="nav header-navbar-rht">
      {isLoggedIn ? (
        <Dropdown className="user-drop nav-item dropdown has-arrow logged-item">
          <Dropdown.Toggle variant="" id="dropdown-basic">
            <img
              className="rounded-circle"
              src={IMG01}
              width="31"
              alt="Darren Elder"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="user-header">
              <div className="avatar avatar-sm">
                <img
                  src={IMG01}
                  alt="User"
                  className="avatar-img rounded-circle"
                />
              </div>
              <div className="user-text">
                <h6>{`${currentUser.firstName} 
                                    ${currentUser.lastName}`}</h6>
                <p className="text-muted mb-0">User</p>
              </div>
            </div>
            {/* <Dropdown.Item href="/account-dashboard">Dashboard</Dropdown.Item> */}
            <Dropdown.Item href="/account-profile">
              Profile Settings
            </Dropdown.Item>
            <Dropdown.Item href="/account-appointments">
              {t("Appointments")}
            </Dropdown.Item>
            <Dropdown.Item href="/" onClick={() => logout()}>
              {t("Logout")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <>
          <li className="nav-item">
            <a href="/login" className="nav-link header-login">
              {t("Login")} / {t("Signup")}
            </a>
          </li>{" "}
        </>
      )}
    </ul>
  );
};

export default MenuLoginSignup;
